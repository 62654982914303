import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import VATImg from 'images/blog-images/shipkoo-vat.png';
import EUFlagMapImg from 'images/blog-images/shipkoo-eu-map.png';
import UKKeyEcommerceReformsFeatured from 'images/featured-image/uk-key-ecommerce-reforms-featured.png';
import NewFBARoutesImg from 'images/blog-images/new-fba-routes.png';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query UKKeyEcommerceReforms {
      file(relativePath: { eq: "uk-key-ecommerce-reforms.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="2021 key ecommerce reforms in the uk"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="What You Need to Know About the 2021 Key eCommerce Reforms in the UK | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-what-you-need-to-know-about-2021-uk" })}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "What You Need to Know About the 2021 Key eCommerce Reforms in the UK",
              content: intl.formatMessage({ id: "blog-post-meta-title-what-you-need-to-know-about-2021-uk" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: UKKeyEcommerceReformsFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="what-you-need-to-know-about-the-2021-key-ecommerce-reforms-in-the-uk"
        title={intl.formatMessage({ id: "what-you-need-to-know-2021-key-ecommerce-head-title" })}
        date="2020-12-15"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">

            <p>
              {intl.formatMessage({ id: "what-you-need-to-know-2021-key-ecommerce-content1" })}
            </p>

            <p>
              {intl.formatMessage({ id: "what-you-need-to-know-2021-key-ecommerce-content2" })}
            </p>

            <Image src={VATImg} className="" alt="value added tax"/>

            <p>
              {intl.formatMessage({ id: "what-you-need-to-know-2021-key-ecommerce-content3" })}
            </p>

            <OrderedList>
              <li>
                {intl.formatMessage({ id: "what-you-need-to-know-2021-key-ecommerce-content4" })}
              </li>
              <li>
                {intl.formatMessage({ id: "what-you-need-to-know-2021-key-ecommerce-content5" })}
                <p>- {intl.formatMessage({ id: "what-you-need-to-know-2021-key-ecommerce-content6" })}</p>
                <p>- {intl.formatMessage({ id: "what-you-need-to-know-2021-key-ecommerce-content7" })}</p>
              </li>
            </OrderedList>

            
            <p>
              {intl.formatMessage({ id: "what-you-need-to-know-2021-key-ecommerce-content8" })}
            </p>

            <Image src={EUFlagMapImg} alt="eu flag" />

            <p>
              {intl.formatMessage({ id: "what-you-need-to-know-2021-key-ecommerce-content9" })}
            </p>

            <UnorderedList>
              <li>
                {intl.formatMessage({ id: "what-you-need-to-know-2021-key-ecommerce-content10" })}
              </li>
              <li>
                {intl.formatMessage({ id: "what-you-need-to-know-2021-key-ecommerce-content11" })}
              </li>
              <li>
                {intl.formatMessage({ id: "what-you-need-to-know-2021-key-ecommerce-content12" })}
              </li>
            </UnorderedList>

            <p>
                {intl.formatMessage({ id: "what-you-need-to-know-2021-key-ecommerce-content13" })}
            </p>

            <Image src={NewFBARoutesImg} alt="new fba and prep routes"/>

            <p>
              <a href="https://shipkoo.com/">Shipkoo</a> {intl.formatMessage({ id: "what-you-need-to-know-2021-key-ecommerce-content14" })} <Link to="/contact/">https://shipkoo.com/contact/</Link>
            </p>

            <BottomShare>
              <div className="column-half">
                <p>{intl.formatMessage({ id: "share-the-article-text" })}</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>{intl.formatMessage({ id: "more-from-shipkoo-text" })}</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;